import app, { AppState } from "./app";
import assets, { AssetsState } from "./assets";
import files from "./files";
import qrCode, { QrCodeState } from "./qrCode";
import tasks, { TasksState } from "./tasks";
import comments, { CommentsState } from "./comments";
import rewards, { RewardsState } from "./rewards";
import notifications, { NotificationsState } from "./notifications";
import user, { UserState } from "./user";
import verify from "./verify";
import diy from "./diy";
import ai from "./ai";

export interface RootState {
  app: AppState;
  assets: AssetsState;
  qrCode: QrCodeState;
  tasks: TasksState;
  comments: CommentsState;
  rewards: RewardsState;
  notifications: NotificationsState;
  user: UserState;
}

export interface ListQuery {
  page_number: number;
  page_size: number;
  ordering?: string | string[]; // 使用 "-"来表示倒序排序，如 -id
  language?: string;
  status?: string;
  content__contains?: string;
  nickname__icontains?: string;
  task_id?: number;
  object_id?: number | null;
  superior_id?: number | null;
  top_id?: number | null;
  user_id?: number;
  mention_user_id?: number;
  ids?: number[];
  group_ids?: number[];
  content_type?: string;
  active_tab?: string;
  type?: string;
}

export default {
  app,
  assets,
  files,
  qrCode,
  tasks,
  comments,
  rewards,
  notifications,
  user,
  verify,
  diy,
  ai
};
