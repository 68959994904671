


























import Vue from "vue";
import { mapState } from "vuex";
import moment from "moment";
import { dispatch, Notification } from "@/store";

export default Vue.extend({
  name: "VNotificationList",
  data() {
    return {
      moment
    };
  },
  computed: {
    ...mapState("app", {
      isCN: "isCN",
      isMobile: "isMobile",
      language: "language"
    }),
    ...mapState("notifications", {
      list: "list",
      query: "query",
      count: "count"
    })
  },
  methods: {
    getList(page_number: number) {
      dispatch.notificationsGetList({
        ...this.query,
        page_number
      });
    },
    moreData() {
      this.getList(Number(this.query.page_number) + 1);
    },
    userInfo(notification: Notification) {
      if (notification.action === "mention") {
        return (
          notification.target_task_info?.user_info ||
          notification.user_info ||
          {}
        );
      } else {
        return notification.user_info || {};
      }
    },
    content(notification: Notification) {
      const {
        action,
        original_type,
        target_task_info,
        target_comment_info,
        original_comment_info
      } = notification;
      let title = target_task_info?.title || "";
      let targetContent =
        target_comment_info?.content.replace(/<[^>]+>/g, "") || "";
      let originalContent =
        original_comment_info?.content.replace(/<[^>]+>/g, "") || "";
      const length = 40;
      if (title && title.length > length)
        title = title.slice(0, length) + "...";
      if (targetContent && targetContent.length > length)
        targetContent = targetContent.slice(0, length) + "...";
      if (originalContent && originalContent.length > length)
        originalContent = originalContent.slice(0, length) + "...";
      switch (action) {
        case "comment":
          return `${this.$t(
            "task.commented"
          )} [ <span>${title}</span> ] : <span>${originalContent}</span>`;
        case "like":
          return `${this.$t("task.liked")} : <span>${targetContent}</span>`;
        case "reply":
          return `${this.$t(
            "task.replied"
          )} [ <span>${targetContent}</span> ] : <span>${originalContent}</span>`;
        case "reward":
          return `${this.$t("task.rewarded")} : <span>${targetContent}</span>`;
        case "mention":
          if (original_type === "consume") {
            return this.$t("task.consumed");
          } else if (original_type === "reward") {
            return `${this.$t(
              "task.rewarded"
            )} : <span>${targetContent}</span>`;
          } else {
            return (
              this.$t("task.mentioned") +
              (targetContent ? ` : <span>${targetContent}</span>` : "")
            );
          }
      }
    },
    onItemClick(item: Notification) {
      dispatch.notificationsMark(item.id).then(() => {
        item.is_read = true;
        dispatch.notificationsGetUnread();
      });
      const path = "/" + this.language + this.$paths.dao;
      const id = (item.target_comment_info || item.original_comment_info)?.id;
      const hash = id ? "/#task-comment-" + id : "/";
      switch (item.action) {
        case "comment":
          item.original_comment_info
            ? window.open(path + item.location_id + hash, "_blank")
            : this.$message.info(
                "" + this.$t("task.comment") + this.$t("task.hasDeleted")
              );
          break;
        case "reply":
          if (!item.original_comment_info) {
            this.$message.info(
              "" + this.$t("task.reply") + this.$t("task.hasDeleted")
            );
          } else if (!item.target_comment_info) {
            this.$message.info(
              "" + this.$t("task.comment") + this.$t("task.hasDeleted")
            );
          } else {
            window.open(path + item.location_id + hash, "_blank");
          }
          break;
        case "like":
          item.target_comment_info
            ? window.open(path + item.location_id + hash, "_blank")
            : this.$message.info(
                "" + this.$t("task.comment") + this.$t("task.hasDeleted")
              );
          break;
        case "reward":
        case "mention":
          window.open(path + item.location_id + hash, "_blank");
          break;
      }
    }
  },
  mounted() {
    this.getList(1);
  }
});
