

















import Vue from "vue";
import { mapState } from "vuex";
import moment from "moment";

export default Vue.extend({
  name: "VTimer",
  props: {
    end: String,
    glitch: Boolean,
    title: String,
    text: String
  },
  data() {
    return {
      timer: 0,
      now: moment()
    };
  },
  computed: {
    ...mapState("app", {
      isCN: "isCN",
      isMobile: "isMobile"
    }),
    time(): string {
      if (this.now.isBefore(this.end)) {
        const diff = moment.duration(moment(this.end).diff(this.now));
        const no = (number: number) => {
          const num = (Array(2).join("0") + number).slice(-2);
          return "<b>" + num[0] + "</b>" + "<b>" + num[1] + "</b>";
        };
        return `${no(diff.days())}:${no(diff.hours())}:${no(
          diff.minutes()
        )}:${no(diff.seconds())}`;
      } else {
        return "";
      }
    }
  },
  mounted() {
    this.$emit("change", this.time);
    this.timer = setInterval(() => {
      this.now = moment();
      this.$emit("change", this.time);
    }, 1000);
  },
  beforeDestroy() {
    clearInterval(this.timer);
  }
});
