import { render, staticRenderFns } from "./VHeader.vue?vue&type=template&id=b5582352&scoped=true&"
import script from "./VHeader.vue?vue&type=script&lang=ts&"
export * from "./VHeader.vue?vue&type=script&lang=ts&"
import style0 from "./VHeader.vue?vue&type=style&index=0&id=b5582352&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b5582352",
  null
  
)

export default component.exports