import axios, { AxiosRequestConfig } from "axios";
import { message } from "ant-design-vue";
import { auth } from "@/utils";
import { dispatch } from "@/store";
import i18n from "@/i18n";

export default ({ headers, ...options }: AxiosRequestConfig) => {
  return new Promise((resolve, reject) => {
    headers = {
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      ...headers
    };

    const token = auth.getToken();
    if (token && !headers["No-Authorization"] && !headers["Authorization"]) {
      headers["Authorization"] = "Bearer " + token;
    }

    delete headers["No-Authorization"];

    if (options.params) {
      for (const key in options.params) {
        options.params[key] =
          options.params[key] !== undefined
            ? String(options.params[key]).trim()
            : undefined;
      }
    }

    axios({
      ...options,
      timeout: 3600 * 1000,
      headers
    })
      .then(function(response) {
        switch (response.status) {
          case 200:
          case 201:
          case 203:
          case 204:
            resolve(response.data || true);
            break;
          default:
            reject(response);
            break;
        }
      })
      .catch(function(error) {
        console.error(error);
        if (error.response) {
          switch (error.response.status) {
            case 401:
              console.error(token);
              auth.setToken("");
              dispatch
                .userSignCheck()
                .then(isSignIn => !isSignIn && location.reload());
              break;
          }
          if (error.response.data.message) {
            message.destroy();
            const text = i18n
              .tc("error." + error.response.data.message.replace(/\.|'/g, ""))
              .replace("error.", "");
            if (text) message.info(text, 5);
          }
          reject(error.response.data);
        }
        reject(error);
      });
  });
};
