







import Vue, { PropType } from "vue";
import { mapState, mapGetters } from "vuex";
import { TasksTag } from "@/store";

export default Vue.extend({
  name: "VTaskTags",
  props: {
    tags: Array as PropType<TasksTag[]>
  },
  computed: {
    ...mapGetters({ allow: "allow" }),
    ...mapState({ user: "user" }),
    ...mapState("app", {
      isCN: "isCN"
    }),
    visible(): boolean {
      return this.tags && this.tags.length && this.user.isSignIn;
    }
  },
  methods: {
    onClick(tag: TasksTag) {
      window.open(this.$paths.dao + "?tag_ids=" + tag.tag_id, "_blank");
    }
  }
});
