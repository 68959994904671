import { Commit } from "vuex";
import { request, url } from "@/api";
import { RootState, ListQuery, Comment, Task } from "@/store";

export interface Notification {
  action: string;
  content: string;
  create_time: string;
  id: number;
  is_active: boolean;
  is_read: boolean;
  publish_at: string;
  sender_id: number;
  sender_type: string;
  location_id: number;
  location_type: string;
  target_comment_info: Comment;
  target_id: number;
  target_task_info: Task;
  original_comment_info: Comment;
  original_id: number;
  original_type: string;
  target_type: string;
  title: string;
  type: string;
  update_time: string;
  user_ids: number[];
  user_info: {
    avatar: string;
    id: number;
    nickname: string;
  };
}

export interface NotificationsState {
  list: Notification[];
  query: ListQuery;
  count: number;
  unreadCount: number;
}

const state: NotificationsState = {
  list: [],
  query: {
    page_number: 1,
    page_size: 20
  },
  count: 0,
  unreadCount: 0
};

interface Context {
  commit: Commit;
  state: NotificationsState;
  rootState: RootState;
}

const actions = {
  async getList({ commit, state }: Context, query: ListQuery) {
    const res: any = await request({
      url: url.notification,
      params: query
    });
    commit(
      "SET_LIST",
      query.page_number == 1 ? res.results : [...state.list, ...res.results]
    );
    commit("SET_QUERY", {
      ...query,
      page_number: res.page_number,
      page_size: res.page_size
    });
    commit("SET_COUNT", res.count);
    return res;
  },
  post(context: Context, notification: Notification) {
    return request({
      method: "post",
      url: url.notification,
      data: notification
    });
  },
  patch(context: Context, notification: Notification) {
    return request({
      method: "patch",
      url: url.notification + "/" + notification.id,
      data: notification
    });
  },
  delete(context: Context, notification: Notification) {
    return request({
      method: "delete",
      url: url.notification + "/" + notification.id
    });
  },
  async getUnread({ commit }: Context) {
    const res: any = await request({
      url: url.notification,
      params: {
        page_number: 1,
        page_size: 1,
        is_read: false
      }
    });
    commit("SET_UNREAD_COUNT", res.count);
    return res;
  },
  mark(context: Context, id: number) {
    return request({
      method: "post",
      url: url.notificationMark,
      data: {
        notification_ids: [id]
      }
    });
  }
};

const mutations = {
  SET_LIST: (state: NotificationsState, list: Notification[]) => {
    state.list = list;
  },
  SET_QUERY: (state: NotificationsState, query: ListQuery) => {
    state.query = query;
  },
  SET_COUNT: (state: NotificationsState, count: number) => {
    state.count = count;
  },
  SET_UNREAD_COUNT: (state: NotificationsState, count: number) => {
    state.unreadCount = count;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
