











































import Vue from "vue";
import { mapState } from "vuex";
import { dispatch } from "@/store";

export default Vue.extend({
  name: "VUpload",
  props: {
    accept: String,
    listType: String,
    size: Number,
    cropRate: Number
  },
  data() {
    return {
      loading: false,
      ossClient: null as any,
      file: new File([], ""),
      progress: "",
      image: "",
      visible: false
    };
  },
  computed: {
    ...mapState(["user"])
  },
  methods: {
    checkSignIn() {
      if (!this.user.isSignIn) {
        dispatch.userSignIn();
      }
    },
    onUpload({ file }: { file: File }) {
      if (file.size / (1024 * 1024) > this.size) {
        this.$message.info("上传文件大小超出限制！");
        return;
      }
      this.file = file;
      if (this.cropRate) {
        this.image = URL.createObjectURL(file);
        this.visible = true;
      } else {
        this.upload(file);
      }
    },
    onCropperConfirm() {
      (this.$refs.cropper as any).getCropBlob((blob: Blob) => {
        const file = new File([blob], this.file.name, { type: this.file.type });
        this.visible = false;
        this.upload(file);
      });
    },
    upload(file: File) {
      this.loading = true;
      this.$message.info({
        content: this.$t("upload.loading") + "",
        duration: 60000
      });
      dispatch
        .filesUpload(file, (e: ProgressEvent) => {
          this.progress = ((e.loaded / e.total) * 100).toFixed(0) + "%";
        })
        .then((res: string) => {
          this.progress = "";
          this.$emit("change", {
            name: this.file.name,
            type: this.file.type.split("/").shift() || "file",
            url: res
          });
          this.loading = false;
          this.$message.destroy();
          this.$message.info("" + this.$t("upload.success"));
        })
        .catch(() => {
          this.loading = false;
          this.$message.destroy();
          this.$message.info("" + this.$t("upload.fail"));
        });
    }
  }
});
