







import Vue, { PropType } from "vue";
import { mapState, mapGetters } from "vuex";
import { User } from "@/store";

export default Vue.extend({
  name: "VMentionedUsers",
  props: {
    users: Array as PropType<User[]>
  },
  computed: {
    ...mapGetters({ allow: "allow" }),
    ...mapState({ user: "user" }),
    visible(): boolean {
      return (
        this.users &&
        this.users.length &&
        this.user.isSignIn &&
        this.allow("user", "retrieve")
      );
    }
  },
  methods: {
    onClick(id: number) {
      window.open(this.$paths.users + id, "_blank");
    }
  }
});
