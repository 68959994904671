import { Commit } from "vuex";
import { request, url } from "@/api";
import { RootState, ListQuery, Comment } from "@/store";

export interface Reward {
  task_id: number;
  number: number;
  comment_id: number;
  comment_info?: Comment;
  id?: number;
  user_id?: number;
  status?: string;
  create_time?: string;
  update_time?: string;
}

export interface RewardsState {
  list: Reward[];
  query: ListQuery;
  count: number;
}

const state: RewardsState = {
  list: [],
  query: {
    page_number: 1,
    page_size: 1000
  },
  count: 0
};

interface Context {
  commit: Commit;
  state: RewardsState;
  rootState: RootState;
}

const actions = {
  async getList({ commit }: Context, query: ListQuery) {
    const res: any = await request({
      url: url.reward,
      params: query
    });
    commit("SET_LIST", res.results);
    commit("SET_QUERY", {
      ...query,
      page_number: res.page_number,
      page_size: res.page_size
    });
    commit("SET_COUNT", res.count);
    return res;
  },
  post(context: Context, reward: Reward) {
    return request({
      method: "post",
      url: url.reward,
      data: reward
    });
  },
  patch(context: Context, reward: Reward) {
    return request({
      method: "patch",
      url: url.reward + "/" + reward.id,
      data: reward
    });
  },
  delete(context: Context, reward: Reward) {
    return request({
      method: "delete",
      url: url.reward + "/" + reward.id
    });
  }
};

const mutations = {
  SET_LIST: (state: RewardsState, list: Reward[]) => {
    state.list = list;
  },
  SET_QUERY: (state: RewardsState, query: ListQuery) => {
    state.query = query;
  },
  SET_COUNT: (state: RewardsState, count: number) => {
    state.count = count;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
