import Vue from "vue";
import VueRouter, { RouteConfig, RawLocation } from "vue-router";
import assets from "@/store/modules/assets";

const oss = assets.state.oss;

const publicPath = "/:language(cn|en)?";

export declare interface Paths {
  root: string;
  home: string;
  userCenter: string;
  userEdit: string;
  users: string;
  userDetail: string;
  dao: string;
  daoDetail: string;
  tasks: string;
  taskDetail: string;
  taskCreate: string;
  taskBatchCreate: string;
  taskEdit: string;
  chat: string;
  draw: string;
  AILogin: string;
  financeLogin: string;
  AMCarpioG2: string;
  AMEmptinessPhonecase16: string;
  cyberboardGoldPaisley: string;
  TheNovelProject: string;
  TheNovelJoin: string;
  AMAfaBlade: string;
  AMNeon80: string;
  AMEmptinessCharger: string;
  AMEditonUserGuide: string;
  AMRGB65: string;
  AMRelic80OG: string;
  cosmoKillerDune: string;
  cyberboardGlacierR2: string;
  AMRelic80: string;
  AMEmptinessPhonecase: string;
  cybercharge35: string;
  AMKeyboardAssessories: string;
  cyberboardR4: string;
  cosmoKillerR2: string;
  AMAfaR2: string;
  carpioAMEditon: string;
  hover: string;
  cyberboardTypingKit: string;
  cyberblade: string;
  diyCompetition: string;
  cyberboardTerminal: string;
  cybermatR2: string;
  cyberboardR3: string;
  cyberboardGB: string;
  cyberboardR2: string;
  backToTheFutureR2: string;
  emergenceKeycaps: string;
  theChimneyCat: string;
  timeMachine: string;
  AngryMiao021C: string;
  icySilverSwitch: string;
  icySilverSwitchPro: string;
  icySilverSwitchProV2: string;
  glacierKeycapSet: string;
  AMAfa: string;
  AMAfaGraffiti: string;
  cosmoKiller: string;
  AM65Less: string;
  AM65LessEN: string;
  AM65LessR2: string;
  AM65LessFading: string;
  AM65LessCustom: string;
  AM65LessCustomEN: string;
  AMMaster: string;
  AMMasterHistory: string;
  AMHatsu: string;
  AMHatsuBattleship: string;
  AMHatsuNumber: string;
  cyberboardXMAS: string;
  cybercharge: string;
  cybermat: string;
  cyberboard: string;
  products: string;
  more: string;
  verify: string;
  cybercoin: string;
  cyberswitch: string;
  ext: string;
  aboutUs: string;
  privacyPolicy: string;
  afterSales: string;
  termsOfService: string;
  userManual: string;
  cyberbladeManual: string;
  cyberboardR2_5DisassemblyGuide: string;
  cyberbladeApp: string;
  shortLink: string;
  qr: string;
}

export const paths: Paths = {
  root: "/",
  home: "/",
  userCenter: "/user-center/",
  userEdit: "/user-edit/",
  users: "/users/",
  userDetail: "/users/:id/",
  dao: "/dao/",
  daoDetail: "/dao/:id/",
  tasks: "/tasks/",
  taskDetail: "/tasks/:id/",
  taskCreate: "/task-create/",
  taskBatchCreate: "/task-batch-create/",
  taskEdit: "/task-create/:id/",
  chat: "/chat/",
  draw: "/draw/cyberboard-r4/",
  AILogin: "/ai-login/",
  financeLogin: "/finance-login/",
  AMCarpioG2: "/am-carpio-g2/",
  AMEmptinessPhonecase16: "/am-emptiness-phone-case-16/",
  cyberboardGoldPaisley: "/cyberboard-gold-paisley/",
  TheNovelProject: "/the-novel-project/",
  TheNovelJoin: "/the-novel-join/",
  AMAfaBlade: "/am-afa-blade/",
  AMNeon80: "/am-neon-80/",
  AMEmptinessCharger: "/am-emptiness-charger/",
  AMEditonUserGuide: "/carpio-g2.0-am-editon/user-guide/",
  AMRGB65: "/am-rgb-65/",
  AMRelic80OG: "/am-relic-80-og/",
  cosmoKillerDune: "/cosmo-killer-dune/",
  cyberboardGlacierR2: "/cyberboard-glacier-r2/",
  AMRelic80: "/am-relic-80/",
  AMEmptinessPhonecase: "/am-emptiness-phone-case/",
  cybercharge35: "/cybercharge-35/",
  AMKeyboardAssessories: "/am-keyboard-assessories/",
  cyberboardR4: "/cyberboard-r4/",
  cosmoKillerR2: "/cosmo-killer-r2/",
  AMAfaR2: "/am-afa-r2/",
  carpioAMEditon: "/carpio-am-editon/",
  hover: "/hover/",
  cyberboardTypingKit: "/cyberboard-typing-kit/",
  cyberblade: "/cyberblade/",
  diyCompetition: "/led-competition/",
  cyberboardTerminal: "/cyberboard-terminal/",
  cybermatR2: "/cybermat-r2/",
  cyberboardR3: "/cyberboard-r3/",
  cyberboardGB: "/glacier/",
  cyberboardR2: "/cyberboard-r2/",
  backToTheFutureR2: "/back-to-the-future-r2/",
  emergenceKeycaps: "/emergence-keycaps/",
  theChimneyCat: "/the-chimney-cat/",
  timeMachine: "/time-machine/",
  AngryMiao021C: "/angry-miao-021c/",
  icySilverSwitch: "/icy-silver-switch/",
  icySilverSwitchPro: "/icy-silver-switch-pro/",
  icySilverSwitchProV2: "/icy-silver-switch-pro-v2/",
  glacierKeycapSet: "/glacier-keycap-set/",
  AMAfa: "/am-afa/",
  AMAfaGraffiti: "/afa-graffiti/",
  cosmoKiller: "/cosmo-killer/",
  AM65Less: "/am-65-less/",
  AM65LessEN: "/am-compact-touch/",
  AM65LessR2: "/am-65-less-r2/",
  AM65LessFading: "/am-65-less-fading/",
  AM65LessCustom: "/am-65-less/custom/",
  AM65LessCustomEN: "/am-compact-touch/custom/",
  AMMaster: "/am-master/",
  AMMasterHistory: "/am-master-history/",
  AMHatsu: "/am-hatsu/",
  AMHatsuBattleship: "/am-hatsu-battleship/",
  AMHatsuNumber: "/am-hatsu/number/",
  cyberboardXMAS: "/cyberboard-xmas/",
  cybercharge: "/cybercharge/",
  cybermat: "/cybermat/",
  cyberboard: "/cyberboard/",
  products: "/products/",
  verify: "/verify/",
  more: "/more/",
  cybercoin: "/cybercoin/",
  cyberswitch: "/cyberswitch/",
  ext: "/ext/",
  aboutUs: "/about-us/",
  privacyPolicy: "/privacy-policy/",
  afterSales: "/after-sales/",
  termsOfService: "/terms-of-service/",
  userManual: "/:id/user-manual/",
  cyberbladeManual: "/cyberblade/manual/",
  cyberboardR2_5DisassemblyGuide: "/cyberboard-r2_5/disassembly-guide/",
  cyberbladeApp: "/cyberblade/app/",
  shortLink: "/sl/:id/",
  qr: "/qr/"
};

const routes: Array<RouteConfig> = [
  {
    path: publicPath + paths.root,
    name: "MainLayout",
    component: () => import("./layouts/MainLayout.vue"),
    children: [
      {
        path: publicPath + paths.home,
        name: "home.name",
        component: () => import("./views/Home.vue")
      },
      {
        path: publicPath + paths.userCenter,
        name: "userCenter",
        component: () => import("./views/UserCenter.vue")
      },
      {
        path: publicPath + paths.userEdit,
        name: "userCenter",
        component: () => import("./views/UserEdit.vue")
      },
      {
        path: publicPath + paths.userDetail,
        name: "userCenter",
        component: () => import("./views/UserCenter.vue")
      },
      {
        path: publicPath + paths.dao,
        name: "DAO",
        component: () => import("./views/Tasks.vue")
      },
      {
        path: publicPath + paths.daoDetail,
        name: "TaskDetail",
        component: () => import("./views/TaskDetail.vue")
      },
      {
        path: publicPath + paths.tasks,
        name: "DAO",
        component: () => import("./views/Tasks.vue")
      },
      {
        path: publicPath + paths.taskDetail,
        name: "TaskDetail",
        component: () => import("./views/TaskDetail.vue")
      },
      {
        path: publicPath + paths.taskCreate,
        name: "TaskCreate",
        component: () => import("./views/TaskCreate.vue")
      },
      {
        path: publicPath + paths.taskEdit,
        name: "TaskEdit",
        component: () => import("./views/TaskCreate.vue")
      },
      {
        path: publicPath + paths.taskBatchCreate,
        name: "TaskBatchCreate",
        component: () => import("./views/TaskBatchCreate.vue")
      },
      {
        path: publicPath + paths.chat,
        name: "CHAT",
        component: () => import("./views/Chat.vue")
      },
      {
        path: publicPath + paths.draw,
        name: "draw.title",
        component: () => import("./views/Draw.vue")
      },
      {
        path: publicPath + paths.AILogin,
        name: "AI Login",
        component: () => import("./views/AILogin.vue")
      },
      {
        path: publicPath + paths.financeLogin,
        name: "Finance Login",
        component: () => import("./views/FinanceLogin.vue")
      },
      {
        path: publicPath + paths.AMCarpioG2,
        name: "AMCarpioG2.name",
        component: () => import("./views/AMCarpioG2.vue")
      },
      {
        path: publicPath + paths.AMEmptinessPhonecase16,
        name: "AMEmptinessPhonecase16.name",
        component: () => import("./views/AMEmptinessPhonecase16.vue")
      },
      {
        path: publicPath + paths.cyberboardGoldPaisley,
        name: "cyberboardGoldPaisley.name",
        component: () => import("./views/CyberboardGoldPaisley.vue")
      },
      {
        path: publicPath + paths.AMAfaBlade,
        name: "AMAfaBlade.name",
        component: () => import("./views/AMAfaBlade.vue")
      },
      {
        path: publicPath + paths.TheNovelProject,
        name: "TheNovelProject.name",
        component: () => import("./views/TheNovelProject.vue")
      },
      {
        path: publicPath + paths.TheNovelJoin,
        name: "TheNovelJoin.name",
        component: () => import("./views/TheNovelJoin.vue")
      },
      {
        path: publicPath + paths.AMNeon80,
        name: "AMNeon80.name",
        component: () => import("./views/AMNeon80.vue")
      },
      {
        path: publicPath + paths.AMEmptinessCharger,
        name: "AMEmptinessCharger.name",
        component: () => import("./views/AMEmptinessCharger.vue")
      },
      {
        path: publicPath + paths.AMEditonUserGuide,
        name: "Carpio G2.0 AM Editon User Guide",
        component: () => import("./views/Video.vue"),
        props: { video: oss.carpio_g2_0_am_editon_user_guide }
      },
      {
        path: publicPath + paths.AMRGB65,
        name: "AMRGB65.name",
        component: () => import("./views/AMRGB65.vue")
      },
      {
        path: publicPath + paths.icySilverSwitchProV2,
        name: "icySilverSwitchProV2.name",
        component: () => import("./views/IcySilverSwitchProV2.vue")
      },
      {
        path: publicPath + paths.AMRelic80OG,
        name: "AMRelic80OG.name",
        component: () => import("./views/AMRelic80OG.vue")
      },
      {
        path: publicPath + paths.cosmoKillerDune,
        name: "cosmoKillerDune.name",
        component: () => import("./views/CosmoKillerDune.vue")
      },
      {
        path: publicPath + paths.cyberboardGlacierR2,
        name: "cyberboardGlacierR2.name",
        component: () => import("./views/CyberboardGlacierR2.vue")
      },
      {
        path: publicPath + paths.AMRelic80,
        name: "AMRelic80.name",
        component: () => import("./views/AMRelic80.vue")
      },
      {
        path: publicPath + paths.AMEmptinessPhonecase,
        name: "AMEmptinessPhonecase.name",
        component: () => import("./views/AMEmptinessPhonecase.vue")
      },
      {
        path: publicPath + paths.cybercharge35,
        name: "cybercharge35.name",
        component: () => import("./views/Cybercharge35.vue")
      },
      {
        path: publicPath + paths.AMKeyboardAssessories,
        name: "AMKeyboardAssessories.name",
        component: () => import("./views/AMKeyboardAssessories.vue")
      },
      {
        path: publicPath + paths.icySilverSwitchPro,
        name: "icySilverSwitchPro.name",
        component: () => import("./views/IcySilverSwitchPro.vue")
      },
      {
        path: publicPath + paths.cyberboardR4,
        name: "cyberboardR4.name",
        component: () => import("./views/CyberboardR4.vue")
      },
      {
        path: publicPath + paths.cosmoKillerR2,
        name: "cosmoKillerR2.name",
        component: () => import("./views/CosmoKillerR2.vue")
      },
      {
        path: publicPath + paths.AMAfaR2,
        name: "AMAfaR2.name",
        component: () => import("./views/AMAfaR2.vue")
      },
      {
        path: publicPath + paths.carpioAMEditon,
        name: "carpioAMEditon.name",
        component: () => import("./views/CarpioAMEditon.vue")
      },
      {
        path: publicPath + paths.hover,
        name: "hover.name",
        component: () => import("./views/Hover.vue")
      },
      {
        path: publicPath + paths.cyberboardTypingKit,
        name: "cyberboardTypingKit.name",
        component: () => import("./views/CyberboardTypingKit.vue")
      },
      {
        path: publicPath + paths.cyberblade,
        name: "cyberblade.name",
        component: () => import("./views/Cyberblade.vue")
      },
      {
        path: publicPath + paths.diyCompetition,
        name: "diyCompetition.name",
        component: () => import("./views/DiyCompetition.vue")
      },
      {
        path: publicPath + paths.cyberboardTerminal,
        name: "cyberboardTerminal.name",
        component: () => import("./views/CyberboardTerminal.vue")
      },
      {
        path: publicPath + paths.cybermatR2,
        name: "cybermatR2.name",
        component: () => import("./views/CybermatR2.vue")
      },
      {
        path: publicPath + paths.cyberboardR3,
        name: "cyberboardR3.name",
        component: () => import("./views/CyberboardR3.vue")
      },
      {
        path: publicPath + paths.cyberboardGB,
        name: "cyberboardGB.name",
        component: () => import("./views/CyberboardGB.vue")
      },
      {
        path: publicPath + paths.cyberboardR2,
        name: "cyberboardR2.name",
        component: () => import("./views/CyberboardR2.vue")
      },
      {
        path: publicPath + paths.backToTheFutureR2,
        name: "backToTheFutureR2.name",
        component: () => import("./views/BackToTheFutureR2.vue")
      },
      {
        path: publicPath + paths.emergenceKeycaps,
        name: "emergenceKeycaps.name",
        component: () => import("./views/EmergenceKeycaps.vue")
      },
      {
        path: publicPath + paths.theChimneyCat,
        name: "theChimneyCat.name",
        component: () => import("./views/TheChimneyCat.vue")
      },
      {
        path: publicPath + paths.timeMachine,
        name: "timeMachine.name",
        component: () => import("./views/TimeMachine.vue")
      },
      {
        path: publicPath + paths.AngryMiao021C,
        name: "AngryMiao021C.name",
        component: () => import("./views/AngryMiao021C.vue")
      },
      {
        path: publicPath + paths.icySilverSwitch,
        name: "icySilverSwitch.name",
        component: () => import("./views/IcySilverSwitch.vue")
      },
      {
        path: publicPath + paths.glacierKeycapSet,
        name: "glacierKeycapSet.name",
        component: () => import("./views/GlacierKeycapSet.vue")
      },
      {
        path: publicPath + paths.AMAfa,
        name: "AMAfa.name",
        component: () => import("./views/AMAfa.vue")
      },
      {
        path: publicPath + paths.AMAfaGraffiti,
        name: "2022 AM AFA DIY 涂鸦大赛",
        component: () => import("./views/AMAfaGraffiti.vue")
      },
      {
        path: publicPath + paths.AM65Less,
        name: "AM 65 Less",
        component: () => import("./views/AM65Less.vue")
      },
      {
        path: publicPath + paths.AM65LessEN,
        name: "AM COMPACT TOUCH",
        component: () => import("./views/AM65Less.vue")
      },
      {
        path: publicPath + paths.AM65LessR2,
        name: "AM 65 Less R2",
        component: () => import("./views/AM65LessR2.vue")
      },
      {
        path: publicPath + paths.AM65LessFading,
        name: "AM 65 LESS FADING",
        component: () => import("./views/AM65LessFading.vue")
      },
      {
        path: publicPath + paths.cosmoKiller,
        name: "cosmoKiller.name",
        component: () => import("./views/CosmoKiller.vue")
      },
      {
        path: publicPath + paths.AM65LessCustom,
        name: "AM 65 Less",
        component: () => import("./views/AM65LessCustom.vue")
      },
      {
        path: publicPath + paths.AM65LessCustomEN,
        name: "AM COMPACT TOUCH",
        component: () => import("./views/AM65LessCustom.vue")
      },
      {
        path: publicPath + paths.AMMaster,
        name: "AM MASTER",
        component: () => import("./views/AMMaster.vue")
      },
      {
        path: publicPath + paths.AMMasterHistory,
        name: "AM MASTER HISTORY",
        component: () => import("./views/AMMasterHistory.vue")
      },
      {
        path: publicPath + paths.AMHatsu,
        name: "AMHatsu.name",
        component: () => import("./views/AMHatsu.vue")
      },
      {
        path: publicPath + paths.AMHatsuBattleship,
        name: "AMHatsuBattleship.name",
        component: () => import("./views/AMHatsuBattleship.vue")
      },
      {
        path: publicPath + paths.AMHatsuNumber,
        name: "AMHatsu.name",
        component: () => import("./views/AMHatsuNumber.vue")
      },
      {
        path: publicPath + paths.cyberboardXMAS,
        name: "cyberboardXMAS.name",
        component: () => import("./views/CyberboardXMAS.vue")
      },
      {
        path: publicPath + paths.cybercharge,
        name: "cybercharge.name",
        component: () => import("./views/Cybercharge.vue")
      },
      {
        path: publicPath + paths.cybermat,
        name: "cybermat.name",
        component: () => import("./views/Cybermat.vue")
      },
      {
        path: publicPath + paths.cyberboard,
        name: "cyberboard.name",
        component: () => import("./views/Cyberboard.vue")
      },
      {
        path: publicPath + paths.products,
        name: "products.name",
        component: () => import("./views/Products.vue")
      },
      {
        path: publicPath + paths.more,
        name: "more.name",
        component: () => import("./views/More.vue")
      },
      {
        path: publicPath + paths.verify + ":name?/",
        name: "verify.name",
        component: () => import("./views/Verify.vue")
      },
      {
        path: publicPath + paths.cybercoin,
        name: "cybercoin.name",
        component: () => import("./views/Cybercoin.vue")
      },
      {
        path: publicPath + paths.cyberswitch,
        name: "cyberswitch.name",
        component: () => import("./views/Cyberswitch.vue")
      },
      {
        path: publicPath + paths.ext,
        name: "ext.name",
        component: () => import("./views/Ext.vue")
      },
      {
        path: publicPath + paths.aboutUs,
        name: "aboutUs.name",
        component: () => import("./views/AboutUs.vue")
      },
      {
        path: publicPath + paths.privacyPolicy,
        name: "Privacy Policy",
        component: () => import("./views/PrivacyPolicy.vue")
      },
      {
        path: publicPath + paths.afterSales,
        name: "afterSales",
        component: () => import("./views/AfterSales.vue")
      },
      {
        path: publicPath + paths.termsOfService,
        name: "termsOfService",
        component: () => import("./views/TermsOfService.vue")
      },
      {
        path: publicPath + paths.userManual,
        name: "User Manual",
        component: () => import("./views/UserManual.vue")
      },
      {
        path: publicPath + paths.cyberbladeManual,
        name: "User Manual",
        component: () => import("./views/UserManual.vue")
      },
      {
        path: publicPath + paths.cyberboardR2_5DisassemblyGuide,
        name: "CYBERBOARD R2.5 Disassembly Guide",
        component: () => import("./views/Video.vue"),
        props: { video: oss.cyberboard_r2_5_disassembly_guide }
      },
      {
        path: publicPath + paths.cyberbladeApp,
        name: "cyberbladeApp.name",
        component: () => import("./views/CyberbladeApp.vue")
      },
      {
        path: publicPath + paths.shortLink,
        component: () => import("./views/ShortLink.vue")
      },
      {
        path: publicPath + paths.qr,
        component: () => import("./views/QR.vue")
      },
      {
        path: publicPath + "/afa-diy/",
        redirect: publicPath + "/dao/309/"
      }
    ]
  },
  {
    path: "*",
    redirect: paths.root
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
  routes
});

const VueRouterPush = VueRouter.prototype.push;
const VueRouterReplace = VueRouter.prototype.replace;

VueRouter.prototype.push = function push(location: RawLocation) {
  return (VueRouterPush.call(this, location) as any).catch((err: Error) => err);
};
VueRouter.prototype.replace = function replace(location: RawLocation) {
  return (VueRouterReplace.call(this, location) as any).catch(
    (err: Error) => err
  );
};

Vue.use(VueRouter);
Vue.prototype.$paths = paths;

export default router;
