













































































































import Vue, { PropType } from "vue";
import { mapState } from "vuex";
import { dispatch, ListQuery, Task, TasksCategory, TasksChain } from "@/store";
import VShare from "./VShare.vue";

export default Vue.extend({
  components: { VShare },
  name: "VTaskList",
  props: {
    query: Object as PropType<ListQuery>,
    tasksGetList: Function
  },
  data() {
    return {
      defaultQuery: {
        page_number: 1,
        page_size: 20,
        ordering: "-id"
      } as ListQuery,
      list: [] as Task[],
      count: 0,
      origin: location.origin
    };
  },
  computed: {
    ...mapState(["user"]),
    ...mapState("app", {
      isCN: "isCN",
      isMobile: "isMobile"
    }),
    ...mapState("assets", {
      images: "images"
    }),
    ...mapState("tasks", {
      categoryList: "categoryList",
      chainList: "chainList"
    })
  },
  methods: {
    getList() {
      const tasksGetList = this.tasksGetList || dispatch.tasksGetList;
      const query = {
        ...this.defaultQuery,
        ...this.query
      };
      tasksGetList(query).then((res: any) => {
        if (res.page_number > 1) {
          this.list = [...this.list, ...res.results];
        } else {
          this.list = res.results;
        }
        this.count = res.count;

        // this.$router.replace({
        //   path: this.$route.path,
        //   query: {
        //     ...this.$route.query,
        //     page_size: String(query.page_size || ""),
        //     page_number: String(query.page_number || "")
        //   }
        // });
      });
    },
    moreData() {
      this.defaultQuery.page_number = Number(this.defaultQuery.page_number) + 1;
      this.getList();
    },
    onPaginationChange(page: number) {
      this.defaultQuery.page_number = page;
      this.list = [];
      this.getList();
    },
    onPageSizeChange(page: number, pageSize: number) {
      this.defaultQuery.page_number = page;
      this.defaultQuery.page_size = pageSize;
      this.list = [];
      this.getList();
    },
    onItemClick(id: number) {
      if (this.isMobile) {
        this.$router.push(this.$paths.dao + id + "/");
      } else {
        window.open(this.$paths.dao + id + "/", "_blank");
      }
    },
    category(id: number): TasksCategory {
      const category = this.categoryList.find(
        (category: TasksCategory) => category.id === id
      );
      return category || { is_public: true };
    },
    chainName(id: number): string {
      const chain = this.chainList.find((i: TasksChain) => i.id === id);
      if (chain && chain.id !== 1) {
        return "[" + chain.coin_name + "]";
      }
      return "";
    },
    categoryName(id: number): string {
      const category = this.categoryList.find(
        (category: TasksCategory) => category.id === id
      );
      return category ? category.name + "" : "";
    }
  },
  watch: {
    query(query, old) {
      if (JSON.stringify(query) !== JSON.stringify(old)) {
        this.getList();
      }
    }
  },
  created() {
    // this.isMobile && (this.defaultQuery.page_size = 20);
    // const { page_size, page_number } = this.$route.query;
    // page_size && (this.defaultQuery.page_size = Number(page_size));
    // page_number && (this.defaultQuery.page_number = Number(page_number));
    this.getList();
  }
});
