

































import Vue, { PropType } from "vue";
import { mapState } from "vuex";
import moment from "moment";
import { dispatch, ListQuery, TasksChain } from "@/store";

export default Vue.extend({
  name: "VBalanceList",
  props: {
    query: Object as PropType<ListQuery>
  },
  data() {
    return {
      moment
    };
  },
  computed: {
    ...mapState("app", {
      isCN: "isCN",
      isMobile: "isMobile"
    }),
    ...mapState("assets", {
      images: "images"
    }),
    ...mapState("user", {
      list: "balanceList",
      balanceQuery: "balanceQuery",
      count: "balanceCount"
    }),
    ...mapState("tasks", {
      chainList: "chainList"
    })
  },
  methods: {
    getList(page_number: number) {
      dispatch.userBalanceList({
        ...this.balanceQuery,
        ...this.query,
        page_number
      });
    },
    moreData() {
      this.getList(Number(this.balanceQuery.page_number) + 1);
    },
    chainName(id: number): string {
      const chain = this.chainList.find((i: TasksChain) => i.id === id);
      if (chain && chain.id !== 1) {
        return "[" + chain.coin_name + "]";
      }
      return "";
    }
  },
  watch: {
    query(query, old) {
      if (JSON.stringify(query) !== JSON.stringify(old)) {
        this.getList(1);
      }
    }
  },
  mounted() {
    this.getList(1);
  }
});
