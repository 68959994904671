




















import Vue, { PropType } from "vue";
import { mapState, mapGetters } from "vuex";
import { Task } from "@/store";

export default Vue.extend({
  name: "VTaskCommentModal",
  props: {
    taskId: Number,
    task: Object as PropType<Task>,
    commentId: Number,
    nickname: String
  },
  data() {
    return {
      visible: false
    };
  },
  computed: {
    ...mapState(["user"]),
    ...mapGetters(["allow"])
  },
  methods: {
    onClick() {
      const { status, operation } = this.task;
      if (
        (!this.user.isSignIn || this.allow("comment", "retrieve")) &&
        status === "processing" &&
        operation === "manual"
      ) {
        this.visible = true;
      } else {
        this.$message.info("" + this.$t("task.disabledReply"));
      }
    },
    onSend() {
      this.visible = false;
      this.$emit("change");
    }
  }
});
