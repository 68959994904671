import { Commit } from "vuex";
import { request, url } from "@/api";
import { RootState, ListQuery, Media, User, dispatch } from "@/store";

export interface Task {
  task_category_id: number;
  title: string;
  medias: Media[];
  content: string;
  max_reward_number: number;
  start_at?: string;
  end_at?: string;
  status: string;
  operation: string;
  id?: number;
  language?: string;
  create_time?: string;
  update_time?: string;
  user_info?: User;
  mention_user_ids?: number[];
  tags?: TasksTag[];
  vote_conf: {
    enable: boolean;
    is_multi: boolean;
    max_num: string;
  };
  vote_content: TasksVote;
  coin_type_id?: number;
}

export interface TasksCategory {
  id: number;
  name: string;
  name_en: string;
}

export interface TasksTag {
  id?: number;
  name?: string;
  name_en?: string;
  tag_id?: number;
  tag_name?: string;
}

export interface TasksVote {
  show_res: boolean;
  vote_list: {
    content: string;
    create_time?: string;
    id?: number;
    pic: string;
    update_time?: string;
    vote_num?: number;
  }[];
  vote_total_num: number;
}

export interface TasksChain {
  chain_hash: string;
  coin_name: string;
  create_time: string;
  id: number;
  update_time: string;
}

export interface TasksState {
  list: Task[];
  categoryList: TasksCategory[];
  tagList: TasksTag[];
  query: ListQuery;
  count: number;
  chainList: TasksChain[];
}

const state: TasksState = {
  list: [],
  categoryList: [],
  tagList: [],
  query: {
    page_number: 1,
    page_size: 1000,
    ordering: "-id"
  },
  count: 0,
  chainList: []
};

interface Context {
  commit: Commit;
  state: TasksState;
  rootState: RootState;
}

const Languages: any = {
  cn: "chinese",
  en: "english",
  ja: "japanese"
};

const actions = {
  async getList({ commit }: Context, query: ListQuery) {
    query.language = Languages[query.language || "en"];
    const res: any = await request({
      url: url.task,
      params: {
        ...query,
        t: new Date().getTime()
      }
    });
    const tasks: Task[] = res.results.filter(
      (task: Task) => task.medias.length
    );
    if (tasks.length) {
      const resList: any = await Promise.all(
        tasks.map(task => dispatch.filesGet([task.medias[0].url], task.id || 0))
      );
      resList.map((item: any) => {
        if (item.length) {
          res.results.find((task: Task) => {
            if (task.medias[0] && task.medias[0].url === item[0].request_url) {
              task.medias[0].url = item[0].sign_url;
              return true;
            }
          });
        }
      });
    }
    commit("SET_LIST", res.results);
    commit("SET_QUERY", {
      ...query,
      page_number: res.page_number,
      page_size: res.page_size
    });
    commit("SET_COUNT", res.count);
    return res;
  },
  async getCategoryList({ commit }: Context) {
    const res: any = await request({
      url: url.taskCategory,
      params: {
        page_number: 1,
        page_size: 1000
      }
    });
    commit("SET_CATEGORY_LIST", res.results);
    return res;
  },
  async getTagList({ commit }: Context) {
    const res: any = await request({
      url: url.taskTag,
      params: {
        page_number: 1,
        page_size: 1000,
        ordering: "id"
      }
    });
    commit("SET_TAG_LIST", res.results || []);
    return res;
  },
  get(context: Context, id: number) {
    return request({
      url: url.task + "/" + id
    });
  },
  post(context: Context, task: Task) {
    task.language = Languages[task.language || "en"];
    return request({
      method: "post",
      url: url.task,
      data: {
        ...task,
        vote_content: task.vote_conf.enable ? task.vote_content.vote_list : []
      }
    });
  },
  patch(context: Context, task: Task) {
    task.language = Languages[task.language || "en"];
    return request({
      method: "patch",
      url: url.task + "/" + task.id,
      data: {
        ...task,
        vote_content: task.vote_conf.enable ? task.vote_content.vote_list : []
      }
    });
  },
  delete(context: Context, task: Task) {
    return request({
      method: "delete",
      url: url.taskX + task.id
    });
  },
  reward(context: Context, task: Task) {
    return request({
      method: "patch",
      url: url.task + "/" + task.id + "/reward-batch",
      data: {}
    });
  },
  getGroupIds(context: Context, tagIds: number[]) {
    return request({
      url: url.taskTagGroup + (tagIds.toLocaleString() || 0)
    });
  },
  vote(context: Context, { id, ids }: { id: number; ids: number[] }) {
    return request({
      method: "post",
      url: url.taskVote,
      data: { task_id: id, vote_ids: ids }
    });
  },
  async getChainList({ commit }: Context) {
    const res: any = await request({
      url: url.chain,
      params: {
        page_number: 1,
        page_size: 1000,
        ordering: "id"
      }
    });
    commit("SET_CHAIN_LIST", res || []);
    return res;
  },
  tagTips(context: Context, ids: number[]) {
    return request({
      method: "post",
      url: url.taskTagTips,
      data: { tag_ids: ids }
    });
  },
  batchCreate(context: Context, data: any) {
    return request({
      method: "post",
      url: url.taskBatchCreate,
      data
    });
  },
  batchDelete(context: Context, data: any) {
    return request({
      method: "post",
      url: url.taskBatchDelete,
      data
    });
  }
};

const mutations = {
  SET_LIST: (state: TasksState, list: Task[]) => {
    state.list = list;
  },
  SET_CATEGORY_LIST: (state: TasksState, categoryList: TasksCategory[]) => {
    state.categoryList = categoryList;
  },
  SET_TAG_LIST: (state: TasksState, tagList: TasksTag[]) => {
    state.tagList = tagList;
  },
  SET_QUERY: (state: TasksState, query: ListQuery) => {
    state.query = query;
  },
  SET_COUNT: (state: TasksState, count: number) => {
    state.count = count;
  },
  SET_CHAIN_LIST: (state: TasksState, chainList: TasksChain[]) => {
    state.chainList = chainList;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
