import Vue from "vue";
import VueI18n from "vue-i18n";
import cn from "./cn.json";
import en from "./en.json";
// import ja from "./ja.json";

Vue.use(VueI18n);

const messages = {
  cn,
  en
  // ja
};

export default new VueI18n({
  locale: "cn",
  messages
});
