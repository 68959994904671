















import Vue from "vue";
import { mapState } from "vuex";

export default Vue.extend({
  name: "VAvatar",
  props: {
    src: String,
    small: Boolean
  },
  computed: {
    ...mapState("app", {
      isMobile: "isMobile"
    }),
    ...mapState("assets", {
      images: "images"
    })
  }
});
