import { render, staticRenderFns } from "./VTaskComments.vue?vue&type=template&id=740c8972&scoped=true&"
import script from "./VTaskComments.vue?vue&type=script&lang=ts&"
export * from "./VTaskComments.vue?vue&type=script&lang=ts&"
import style0 from "./VTaskComments.vue?vue&type=style&index=0&id=740c8972&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "740c8972",
  null
  
)

export default component.exports