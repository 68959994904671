import { Commit } from "vuex";
import { RootState } from "@/store";
import { request, url } from "@/api";

interface Context {
  commit: Commit;
  rootState: RootState;
}

const actions = {
  getCyberbladeApp(context: Context, platform: "android" | "ios") {
    return request({
      url: url.cyberbladeApp,
      params: {
        app_code: "tws_app",
        platform,
        version: 1
      }
    });
  },
  getAMMaster(context: Context, platform: "windows" | "mac") {
    return request({
      url: url.historyVersion,
      params: {
        app_code: "AM_Master",
        platform
      }
    });
  }
};

const mutations = {};

export default {
  namespaced: true,
  actions,
  mutations
};
