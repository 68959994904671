import weChat from "weixin-js-sdk";

export { default as auth } from "./auth";

export const deepCopy = (object: object) => JSON.parse(JSON.stringify(object));

export const download = (fileOrUrl: File | string, fileName?: string) => {
  let url = "";
  if (typeof fileOrUrl !== "string") {
    if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
      (window.navigator as any).msSaveOrOpenBlob(fileOrUrl, fileName);
    } else {
      url = URL.createObjectURL(fileOrUrl);
    }
  } else {
    url = fileOrUrl;
  }
  const a = document.createElement("a");
  a.href = url;
  a.target = "_blank";
  a.download = fileName || url.substring(url.lastIndexOf("/") + 1);
  a.click();
};

export const imagesLoader = (images: Array<string>, callback: Function) => {
  const total = images.length;
  let completed = 0;
  images.map(src => {
    const image = new Image();
    image.src = src;
    image.onload = () => {
      callback && callback(++completed / total === 1);
    };
    image.onerror = () => {
      console.error("TOOL Error imagesLoader:" + src);
      callback && callback(++completed / total === 1);
    };
  });
};

export const importAll = (requireContext: any, exclude?: Array<string>) => {
  // https://webpack.js.org/guides/dependency-management/#requirecontext
  // you do not need `import item from './items/item'`
  // it will auto require all item from items file
  return requireContext.keys().reduce((all: any, path: string) => {
    const name = path.replace(/^.*\/(.*)\.\w+$/, "$1");
    if (!(exclude && exclude.includes(name))) {
      const value = requireContext(path);
      all[name] = value.default || value;
    }
    return all;
  }, {});
};

export const share = ({
  title,
  desc,
  link,
  imgUrl
}: {
  title: string;
  desc: string;
  link: string;
  imgUrl: string;
}) => {
  weChat.ready(function() {
    weChat.updateAppMessageShareData({
      title, // 分享标题
      desc, // 分享描述
      link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
      imgUrl // 分享图标
    });
    weChat.updateTimelineShareData({
      title, // 分享标题
      link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
      imgUrl // 分享图标
    });
  });
  document.title = title;
  (document.querySelector(
    'meta[name="description"]'
  ) as HTMLMetaElement).setAttribute("content", desc);
  (document.querySelector(
    'meta[property="og:title"]'
  ) as HTMLMetaElement).setAttribute("content", title);
  (document.querySelector(
    'meta[property="og:description"]'
  ) as HTMLMetaElement).setAttribute("content", desc);
  (document.querySelector(
    'meta[property="og:image"]'
  ) as HTMLMetaElement).setAttribute("content", imgUrl);
};
