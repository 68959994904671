import { Commit } from "vuex";
import { request, url } from "@/api";
import { RootState } from "@/store";

export interface Media {
  name: string;
  type: string;
  url: string;
}

interface Context {
  commit: Commit;
  rootState: RootState;
}

const actions = {
  async upload(
    context: Context,
    {
      file,
      callback
    }: { file: File; path: string; callback: (e: ProgressEvent) => void }
  ) {
    const res: any = await request({
      method: "POST",
      url: url.uploadSign,
      data: {
        file_prefix: "task",
        file_type: file.name.split(".").pop(),
        file_name: file.name.substring(0, file.name.lastIndexOf("."))
      }
    });
    const formData = new FormData();
    formData.append("OSSAccessKeyId", res.access_id);
    formData.append("policy", res.policy);
    formData.append("Signature", res.signature);
    formData.append("key", res.path);
    formData.append("file", file);
    formData.append("success_action_status", "200");
    await request({
      method: "POST",
      url: res.host,
      data: formData,
      onUploadProgress: callback
    });
    return res.private_bucket_sign_url;
  },
  async get(
    context: Context,
    { fileList, taskId }: { fileList: string[]; taskId: number }
  ) {
    const res: any = await request({
      method: "POST",
      url: url.bucketSign,
      data: {
        file_list: fileList,
        task_id: taskId
      }
    });
    return res;
  }
};

export default {
  namespaced: true,
  actions
};
