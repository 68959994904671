














































import Vue, { PropType } from "vue";
import { mapState, mapGetters } from "vuex";
import { dispatch, Task } from "@/store";

export default Vue.extend({
  name: "VTaskRewards",
  props: {
    taskId: Number,
    task: Object as PropType<Task>
  },
  data() {
    return {};
  },
  computed: {
    ...mapState(["user"]),
    ...mapState("app", {
      isCN: "isCN",
      isMobile: "isMobile"
    }),
    ...mapState("assets", {
      images: "images"
    }),
    ...mapState("rewards", {
      list: "list",
      query: "query",
      count: "count"
    }),
    ...mapGetters(["allow"])
  },
  methods: {
    getList() {
      dispatch.rewardsGetList({
        ...this.query,
        task_id: this.taskId
      });
    },
    moreData() {
      this.query.page_number = Number(this.query.page_number) + 1;
      this.getList();
    },
    onReward() {
      dispatch
        .tasksReward(this.task)
        .then(() => {
          this.getList();
          this.$message.info("" + this.$t("task.rewardSuccess"));
        })
        .catch(() => {
          this.getList();
        });
    }
  },
  mounted() {
    if (this.taskId) {
      this.getList();
    }
  }
});
