import { Commit } from "vuex";
import { request, url } from "@/api";
import { RootState, ListQuery, Media, Reward } from "@/store";

export interface Comment {
  content: string;
  content_type: "coin_task";
  object_id: number;
  superior_id?: number;
  id?: number;
  user_id?: number;
  layer?: number;
  medias?: Media[];
  status?: string;
  is_like?: boolean;
  create_time?: string;
  update_time?: string;
  reward_info?: Reward;
  reply_count?: number;
  like_count?: number;
  mention_user_ids?: number[];
}

export interface CommentsState {
  list: Comment[];
  query: ListQuery;
  count: number;
}

const state: CommentsState = {
  list: [],
  query: {
    page_number: 1,
    page_size: 1000,
    ordering: "-id",
    content_type: "coin_task",
    superior_id: 0
  },
  count: 0
};

interface Context {
  commit: Commit;
  state: CommentsState;
  rootState: RootState;
}

const actions = {
  async getList({ commit }: Context, query: ListQuery) {
    const res: any = await request({
      url: url.comment,
      params: {
        ...query,
        t: new Date().getTime()
      }
    });
    commit("SET_LIST", res.results);
    commit("SET_QUERY", {
      ...query,
      page_number: res.page_number,
      page_size: res.page_size
    });
    commit("SET_COUNT", res.count);
    return res;
  },
  post(context: Context, comment: Comment) {
    return request({
      method: "post",
      url: url.comment,
      data: comment
    });
  },
  patch(context: Context, comment: Comment) {
    return request({
      method: "patch",
      url: url.comment + "/" + comment.id,
      data: comment
    });
  },
  delete(context: Context, comment: Comment) {
    return request({
      method: "delete",
      url: url.comment + "/" + comment.id
    });
  },
  like(context: Context, id: number) {
    return request({
      method: "post",
      url: url.commentLike,
      data: {
        comment_id: id
      }
    });
  },
  unlike(context: Context, id: number) {
    return request({
      method: "post",
      url: url.commentUnlike,
      data: {
        comment_id: id
      }
    });
  },
  likeCheck(context: Context, ids: number[]) {
    return request({
      method: "post",
      url: url.commentLikeCheck,
      data: {
        comment_ids: ids
      }
    });
  }
};

const mutations = {
  SET_LIST: (state: CommentsState, list: Comment[]) => {
    state.list = list;
  },
  SET_QUERY: (state: CommentsState, query: ListQuery) => {
    state.query = query;
  },
  SET_COUNT: (state: CommentsState, count: number) => {
    state.count = count;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
