import { render, staticRenderFns } from "./VNotificationList.vue?vue&type=template&id=c6b75662&scoped=true&"
import script from "./VNotificationList.vue?vue&type=script&lang=ts&"
export * from "./VNotificationList.vue?vue&type=script&lang=ts&"
import style0 from "./VNotificationList.vue?vue&type=style&index=0&id=c6b75662&lang=scss&scoped=true&"
import style1 from "./VNotificationList.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c6b75662",
  null
  
)

export default component.exports