import Vue from "vue";
import MdButton from "vue-material/dist/components/MdButton";
import MdDialog from "vue-material/dist/components/MdDialog";
// import MdField from "vue-material/dist/components/MdField";
import MdList from "vue-material/dist/components/MdList";
import MdMenu from "vue-material/dist/components/MdMenu";
import MdRadio from "vue-material/dist/components/MdRadio";
import MdSwitch from "vue-material/dist/components/MdSwitch";
import MdTabs from "vue-material/dist/components/MdTabs";
import "./index.scss";

Vue.use(MdButton);
Vue.use(MdDialog);
// Vue.use(MdField);
Vue.use(MdList);
Vue.use(MdMenu);
Vue.use(MdRadio);
Vue.use(MdSwitch);
Vue.use(MdTabs);
