























import Vue from "vue";
import { Swiper } from "swiper";
import { mapState } from "vuex";

export default Vue.extend({
  name: "VPageSwiper",
  props: {
    headerTitle: String,
    headerName: String,
    headerType: String,
    titleList: Array,
    options: Object
  },
  data() {
    return {
      isHeaderCollapsed: false,
      defaultOptions: {
        direction: "vertical",
        slidesPerView: "auto",
        speed: 600,
        mousewheel: {
          thresholdDelta: 100,
          thresholdTime: 100
        },
        shortSwipes: false,
        longSwipesMs: 50,
        longSwipesRatio: 0.05,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
          renderBullet: (index: number, className: string) => {
            return `<div class="${className}">
              <div class="text">
                ${this.titleList[index] || ""}
              </div>
            </div>`;
          }
        }
        // on: {
        //   afterInit: (swiper: Swiper) => {
        //     swiper.slides.map(slide => {
        //       const node = document.createElement("i");
        //       node.setAttribute(
        //         "class",
        //         "iconfont icon-arrow-down swiper-next"
        //       );
        //       node.addEventListener("click", () => {
        //         swiper.slideNext(600);
        //       });
        //       slide.appendChild(node);
        //     });
        //   }
        // }
      }
    };
  },
  computed: {
    ...mapState("app", {
      isMobile: "isMobile"
    }),
    mixinOptions(): object {
      return {
        ...this.defaultOptions,
        ...this.options
      };
    },
    swiper(): Swiper {
      return (this.$refs.swiper as any).$swiper;
    }
  },
  methods: {
    onSlideChange(swiper: Swiper) {
      const activeVideos = swiper.slides[swiper.realIndex].getElementsByTagName(
        "video"
      );
      const previousVideos = swiper.slides[
        swiper.previousIndex
      ].getElementsByTagName("video");
      for (let i = 0; i < activeVideos.length; i++) {
        // activeVideos[i].play().catch(e => e);
      }
      for (let i = 0; i < previousVideos.length; i++) {
        previousVideos[i].pause();
      }
      this.setPage(swiper.realIndex);
      this.isHeaderCollapsed = swiper.realIndex !== 0;
    },
    setPage(page: number) {
      this.$router.replace({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          p: page.toString()
        }
      });
    },
    onKeydown(e: KeyboardEvent) {
      switch (e.code) {
        case "Space":
        case "Enter":
        case "ArrowDown":
          this.swiper.slideNext();
          break;
        case "ArrowUp":
          this.swiper.slidePrev();
          break;

        default:
          break;
      }
    },
    onResize() {
      this.swiper.updateSize();
    }
  },
  watch: {
    $route(route) {
      const { p } = route.query;
      if (p) {
        if (Number(p) !== this.swiper.realIndex) {
          setTimeout(() => {
            this.swiper.slideTo(Number(p));
          }, 200);
        }
      } else {
        this.swiper.slideTo(0);
      }
    }
  },
  mounted() {
    const { p } = this.$route.query;
    if (p) {
      this.swiper.slideTo(Number(p));
    }
    window.addEventListener("keydown", this.onKeydown);
    window.addEventListener("resize", this.onResize);
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.onKeydown);
    window.removeEventListener("resize", this.onResize);
  }
});
