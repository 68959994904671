



















import Vue, { PropType } from "vue";
import { mapState, mapGetters } from "vuex";
import { ProductName } from "@/store";

export default Vue.extend({
  name: "VTag",
  props: {
    name: String as PropType<ProductName>,
    mini: Boolean
  },
  computed: {
    ...mapState("app", {
      isCN: "isCN",
      isMobile: "isMobile"
    }),
    ...mapGetters({ getStatus: "getStatus" }),
    status(): string {
      return this.getStatus(this.name);
    }
  }
});
