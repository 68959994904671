import store, {
  Oauth2,
  ListQuery,
  ProductName,
  QrCode,
  User,
  Task,
  Comment,
  Reward,
  Notification,
  Verify
} from "@/store";

// 查看更多商品信息

const learnMore = (name: ProductName) => {
  return store.dispatch("learnMore", name);
};

// 立即下单

const orderNow = (name?: ProductName) => {
  return store.dispatch("orderNow", name);
};

// 想要

const request = (data: {
  name: ProductName;
  nickname: string;
  email?: string;
  mobile?: string;
}) => {
  return store.dispatch("request", data);
};

// 想要信息

const requestInfo = (name: ProductName) => {
  return store.dispatch("requestInfo", name);
};

// 想要数量统计

const requestCount = (name: ProductName) => {
  return store.dispatch("requestCount", name);
};

// 加入我们

const followUs = (name?: ProductName) => {
  return store.dispatch("followUs", name);
};

// 联系我们

const contactUs = () => {
  return store.dispatch("contactUs");
};

// 订单

const order = (orderNo: string) => {
  return store.dispatch("order", orderNo);
};

// AM AFA 定制

const AMAfaCustom = ({
  orderNo,
  platform
}: {
  orderNo: string;
  platform: string;
}) => {
  return store.dispatch("AMAfaCustom", { orderNo, platform });
};

// AM AFA 定制

const AMAfaCustomPost = (data: {
  custom_info: {
    left: string;
    right: string;
    top: string;
  };
  order_no: string;
  email: string;
  platform: string;
}) => {
  return store.dispatch("AMAfaCustomPost", data);
};

// AM 65 Less 抽奖

const draw = (code: string) => {
  return store.dispatch("draw", code);
};

// AM 65 Less 抽奖

const drawPost = (data: {
  code: string;
  nickname: string;
  phone: string;
  email: string;
}) => {
  return store.dispatch("drawPost", data);
};

// 二维码

const qrCodeSet = (qrCode: QrCode) => {
  return store.dispatch("qrCode/set", qrCode);
};

// 文件上传

const filesUpload = (file: File, callback?: (e: ProgressEvent) => void) => {
  return store.dispatch("files/upload", {
    file,
    callback
  });
};

const filesGet = (fileList: string[], taskId: number) => {
  return store.dispatch("files/get", {
    fileList,
    taskId
  });
};

// 应用设置

const appSetLanguage = (language: string) => {
  return store.dispatch("app/setLanguage", language);
};

const appCheckIP = () => {
  return store.dispatch("app/checkIP");
};

const appCheckMobile = () => {
  return store.dispatch("app/checkMobile");
};

const appGetOauth2Share = (oauth2: Oauth2) => {
  return store.dispatch("app/getOauth2Share", oauth2);
};

const appGetSettings = (data: any) => {
  return store.dispatch("app/getSettings", data);
};

// 任务

const tasksGetList = (query: ListQuery) => {
  return store.dispatch("tasks/getList", query);
};

const tasksGetCategoryList = () => {
  return store.dispatch("tasks/getCategoryList");
};

const tasksGetTagList = () => {
  return store.dispatch("tasks/getTagList");
};

const tasksGet = (id: number) => {
  return store.dispatch("tasks/get", id);
};

const tasksPost = (task: Task) => {
  return store.dispatch("tasks/post", task);
};

const tasksPatch = (task: Task) => {
  return store.dispatch("tasks/patch", task);
};

const tasksDelete = (task: Task) => {
  return store.dispatch("tasks/delete", task);
};

const tasksReward = (task: Task) => {
  return store.dispatch("tasks/reward", task);
};

const tasksGetGroupIds = (tagIds: number[]) => {
  return store.dispatch("tasks/getGroupIds", tagIds);
};

const tasksVote = ({ id, ids }: { id: number; ids: number[] }) => {
  return store.dispatch("tasks/vote", { id, ids });
};

const tasksGetChainList = () => {
  return store.dispatch("tasks/getChainList");
};

const tasksTagTips = (ids: number[]) => {
  return store.dispatch("tasks/tagTips", ids);
};

const tasksBatchCreate = (data: any) => {
  return store.dispatch("tasks/batchCreate", data);
};

const tasksBatchDelete = (data: any) => {
  return store.dispatch("tasks/batchDelete", data);
};

// 任务评论

const commentsGetList = (query: ListQuery) => {
  return store.dispatch("comments/getList", query);
};

const commentsPost = (comment: Comment) => {
  return store.dispatch("comments/post", comment);
};

const commentsPatch = (comment: Comment) => {
  return store.dispatch("comments/patch", comment);
};

const commentsDelete = (comment: Comment) => {
  return store.dispatch("comments/delete", comment);
};

const commentsLike = (id: number) => {
  return store.dispatch("comments/like", id);
};

const commentsUnlike = (id: number) => {
  return store.dispatch("comments/unlike", id);
};

const commentsLikeCheck = (ids: number[]) => {
  return store.dispatch("comments/likeCheck", ids);
};

// 任务奖励

const rewardsGetList = (query: ListQuery) => {
  return store.dispatch("rewards/getList", query);
};

const rewardsPost = (reward: Reward) => {
  return store.dispatch("rewards/post", reward);
};

const rewardsPatch = (reward: Reward) => {
  return store.dispatch("rewards/patch", reward);
};

const rewardsDelete = (reward: Reward) => {
  return store.dispatch("rewards/delete", reward);
};

// 系统通知

const notificationsGetList = (query: ListQuery) => {
  return store.dispatch("notifications/getList", query);
};

const notificationsPost = (notification: Notification) => {
  return store.dispatch("notifications/post", notification);
};

const notificationsPatch = (notification: Notification) => {
  return store.dispatch("notifications/patch", notification);
};

const notificationsDelete = (notification: Notification) => {
  return store.dispatch("notifications/delete", notification);
};

const notificationsGetUnread = () => {
  return store.dispatch("notifications/getUnread");
};

const notificationsMark = (id: number) => {
  return store.dispatch("notifications/mark", id);
};

// 用户

const userSignCheck = () => {
  return store.dispatch("user/signCheck");
};

const userSignIn = () => {
  return store.dispatch("user/signIn");
};

const userSignOut = () => {
  return store.dispatch("user/signOut");
};

const userGet = () => {
  return store.dispatch("user/get");
};

const userGetList = (query: ListQuery) => {
  return store.dispatch("user/getList", query);
};

const userPatch = (user: User) => {
  return store.dispatch("user/patch", user);
};

const userBalance = (coin_type_id?: number) => {
  return store.dispatch("user/balance", coin_type_id);
};

const userBalanceList = (query: ListQuery) => {
  return store.dispatch("user/balanceList", query);
};

const userTaskRecord = (query: ListQuery) => {
  return store.dispatch("user/taskRecord", query);
};

const userGetPermission = () => {
  return store.dispatch("user/getPermission");
};

const userGetGroupList = () => {
  return store.dispatch("user/getGroupList");
};

const userGetGroupUsers = (query: ListQuery) => {
  return store.dispatch("user/getGroupUsers", query);
};

const userGetGroup = () => {
  return store.dispatch("user/getGroup");
};

const userGetInHouseUserIds = () => {
  return store.dispatch("user/getInHouseUserIds");
};

const userWalletTransfer = (data: any) => {
  return store.dispatch("user/walletTransfer", data);
};

// 防伪

const verifyCheck = (verify: Verify) => {
  return store.dispatch("verify/check", verify);
};

// DIY

const diyGetCyberbladeApp = (platform: "android" | "ios") => {
  return store.dispatch("diy/getCyberbladeApp", platform);
};

const diyGetAMMaster = (platform: "windows" | "mac") => {
  return store.dispatch("diy/getAMMaster", platform);
};

// AI

const aiChat = (data: any) => {
  return store.dispatch("ai/chat", data);
};

const aiAddPrompt = (data: { text: string; token: string }) => {
  return store.dispatch("ai/addPrompt", data);
};

const aiAsr = (audio: Blob) => {
  return store.dispatch("ai/asr", audio);
};

const aiSignInPhoneCode = (phone: string) => {
  return store.dispatch("ai/signInPhoneCode", phone);
};

const aiSignInPhone = (data: { phone: string; code: string }) => {
  return store.dispatch("ai/signInPhone", data);
};

export default {
  learnMore,
  orderNow,
  request,
  requestInfo,
  requestCount,
  followUs,
  contactUs,
  order,
  AMAfaCustom,
  AMAfaCustomPost,
  draw,
  drawPost,
  qrCodeSet,
  filesUpload,
  filesGet,

  appSetLanguage,
  appCheckIP,
  appCheckMobile,
  appGetOauth2Share,
  appGetSettings,

  tasksGetList,
  tasksGetCategoryList,
  tasksGetTagList,
  tasksGet,
  tasksPost,
  tasksPatch,
  tasksDelete,
  tasksReward,
  tasksGetGroupIds,
  tasksVote,
  tasksGetChainList,
  tasksTagTips,
  tasksBatchCreate,
  tasksBatchDelete,

  commentsGetList,
  commentsPost,
  commentsPatch,
  commentsDelete,
  commentsLike,
  commentsUnlike,
  commentsLikeCheck,

  rewardsGetList,
  rewardsPost,
  rewardsPatch,
  rewardsDelete,

  notificationsGetList,
  notificationsPost,
  notificationsPatch,
  notificationsDelete,
  notificationsGetUnread,
  notificationsMark,

  userSignCheck,
  userSignIn,
  userSignOut,
  userGet,
  userGetList,
  userPatch,
  userBalance,
  userBalanceList,
  userTaskRecord,
  userGetPermission,
  userGetGroupList,
  userGetGroupUsers,
  userGetGroup,
  userGetInHouseUserIds,
  userWalletTransfer,

  verifyCheck,

  diyGetCyberbladeApp,
  diyGetAMMaster,

  aiChat,
  aiAddPrompt,
  aiAsr,
  aiSignInPhoneCode,
  aiSignInPhone
};
