














import Vue from "vue";
import { mapState } from "vuex";
import { dispatch } from "@/store";

export default Vue.extend({
  name: "VQrCodeDialog",
  computed: {
    ...mapState({
      qrCode: "qrCode"
    })
  },
  methods: {
    onClose() {
      dispatch.qrCodeSet({
        ...this.qrCode,
        visible: false
      });
    }
  }
});
