










import Vue from "vue";
import { mapState } from "vuex";
import zhCN from "ant-design-vue/es/locale/zh_CN";
import en_US from "ant-design-vue/es/locale/en_US";
import ja_JP from "ant-design-vue/es/locale/ja_JP";
import weChat from "weixin-js-sdk";
import { dispatch } from "@/store";
import { share } from "@/utils";

export default Vue.extend({
  data() {
    return {
      loading: true
    };
  },
  computed: {
    ...mapState("app", {
      appName: "name",
      isInWeChat: "isInWeChat",
      language: "language"
    }),
    locale(): any {
      switch (this.language) {
        case "cn":
          return zhCN;
        case "en":
          return en_US;
        case "ja":
          return ja_JP;
        default:
          return zhCN;
      }
    }
  },
  methods: {
    smartScale() {
      const width = document.body.offsetWidth;
      const height = document.body.offsetHeight;
      let scaleWidth = width;
      if (width / height > 1920 / 1080) {
        scaleWidth = height * (1920 / 1080);
      }
      scaleWidth =
        scaleWidth < 1300
          ? scaleWidth + (1300 - scaleWidth) * 0.625
          : scaleWidth;
      document.documentElement.style.fontSize = scaleWidth / 19.2 + "px";
      document.body.style.fontSize = 16 + "px";
      dispatch.appCheckMobile();
    },
    setWeChatShare() {
      setTimeout(() => {
        dispatch
          .appGetOauth2Share({ channel: "wechat", action: "get_info" })
          .then((res: any) => {
            weChat.config({
              debug: false, // 开启调试模式,
              appId: res.app_id, // 必填 appid
              timestamp: res.timestamp, // 必填，生成签名的时间戳
              nonceStr: res.nonce_str, // 必填，生成签名的随机串
              signature: res.signature, // 必填，签名，见附录1
              jsApiList: [
                "updateAppMessageShareData",
                "updateTimelineShareData",
                "onMenuShareAppMessage",
                "onMenuShareTimeline",
                "chooseWXPay"
              ] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
            });
          });
      }, 500);
      share({
        title: "Angry Miao",
        desc: "Future Art Community",
        link: window.location.href,
        imgUrl: "https://www.angrymiao.com/logo.png"
      });
    }
  },
  watch: {
    $route(to) {
      document.title = to.name ? this.$t(to.name) : this.appName;
    },
    language() {
      document.title = this.$route.name
        ? this.$t(this.$route.name)
        : this.appName;
    }
  },
  created() {
    this.smartScale();
    window.addEventListener("resize", this.smartScale);

    dispatch.appGetSettings({}).then(() => {
      dispatch.appGetSettings({ t: new Date().getTime() });
    });

    dispatch.userSignCheck().then(isSignIn => {
      if (isSignIn) {
        dispatch
          .userGet()
          .then(() => {
            this.loading = false;
            dispatch.userGetPermission();
            dispatch.userGetGroupList().then(dispatch.userGetGroup);
            dispatch.tasksGetCategoryList();
            dispatch.tasksGetTagList();
            dispatch.tasksGetChainList();
            setInterval(() => {
              dispatch.notificationsGetUnread();
            }, 100000);
            dispatch.notificationsGetUnread();
          })
          .catch(() => {
            this.loading = false;
          });
      } else {
        this.loading = false;
      }
    });

    if (this.isInWeChat) {
      this.setWeChatShare();
    }
  },
  mounted() {
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    const vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty("--vh", `${vh}px`);

    // We listen to the resize event
    window.addEventListener("resize", () => {
      // We execute the same script as before
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    });
  }
});
