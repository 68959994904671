





































import Vue from "vue";
import { mapState, mapGetters } from "vuex";
import { quillEditor, Quill } from "vue-quill-editor";
import "quill-mention";
import { dispatch, User } from "@/store";

const Inline = Quill.import("blots/inline");
const Parchment = Quill.import("parchment");

const ATTRIBUTES = ["href", "rel", "target", "download", "class"];

const LinkClass = new Parchment.Attributor.Class("link", "ql-link", {
  scope: Parchment.Scope.INLINE,
  whitelist: ["file"]
});

class InternalLink extends Inline {
  static create(value: any) {
    const node = super.create(value);
    value.href && node.setAttribute("href", value.href);
    value.rel && node.setAttribute("rel", value.rel);
    value.target && node.setAttribute("target", value.target);
    value.download && node.setAttribute("download", value.download);
    return node;
  }

  static formats(domNode: any) {
    return ATTRIBUTES.reduce((formats: any, attribute: any) => {
      if (domNode.hasAttribute(attribute)) {
        formats[attribute] = domNode.getAttribute(attribute);
      }
      return formats;
    }, {});
  }
}

InternalLink.blotName = "file";
InternalLink.className = "file";
InternalLink.tagName = "A";

Quill.register({
  "attributors/class/link": LinkClass,
  "formats/internal_link": InternalLink
});

export default Vue.extend({
  name: "VTaskCommentEditor",
  components: {
    quillEditor
  },
  props: {
    taskId: Number,
    commentId: Number,
    simple: Boolean
  },
  data() {
    return {
      addRange: null as any,
      comment: "",
      text: ""
    };
  },
  computed: {
    ...mapState({ user: "user" }),
    ...mapState("app", {
      isMobile: "isMobile"
    }),
    ...mapState("assets", {
      images: "images"
    }),
    ...mapGetters({ allow: "allow" }),
    editor(): any {
      return (this.$refs.editor as any).quill;
    },
    editorOption(): any {
      return {
        placeholder: this.commentId
          ? this.$t("task.replyPlaceholder")
          : this.$t("task.commentPlaceholder"),
        modules: {
          toolbar: [],
          mention: {
            allowedChars: /^\S*$/,
            mentionDenotationChars: ["@"],
            showDenotationChar: false,
            source: async (searchTerm: string, renderList: any) => {
              if (
                this.allow("user", "retrieve") &&
                this.allow("comment", "mention")
              ) {
                // dispatch.userGetInHouseUserIds().then(ids => {
                dispatch
                  .userGetList({
                    // ids,
                    page_size: 100,
                    page_number: 1,
                    nickname__icontains: searchTerm,
                    type: "comment"
                  })
                  .then(res => {
                    renderList(
                      res.results.map((user: User) => ({
                        ...user,
                        avatar: user.avatar || this.images.avatar,
                        value: "@" + user.nickname
                      }))
                    );
                  });
                // });
              }
            },
            renderItem(user: any) {
              return `<img src="${user.avatar}"/><pann>${user.nickname}</span>`;
            }
          }
        }
      };
    },
    sendDisabled(): boolean {
      return !this.text.trim() && !this.comment.includes("<img");
    }
  },
  methods: {
    onImagePasted(image: HTMLImageElement, delta: any) {
      const arr = image.src.split(",") as any;
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[1]);
      let length = bstr.length;
      const u8arr = new Uint8Array(length);

      while (length--) {
        u8arr[length] = bstr.charCodeAt(length);
      }

      const file = new File([u8arr], mime.replace("/", "."), { type: mime });

      this.$message.info({
        content: this.$t("upload.loading") + "",
        duration: 60000
      });
      dispatch
        .filesUpload(file)
        .then((url: string) => {
          this.$message.destroy();
          this.$message.info("" + this.$t("upload.success"));
          this.addRange = this.editor.getSelection();
          const index = this.addRange ? this.addRange.index : 0;
          this.editor.insertEmbed(index, "image", url);
        })
        .catch(() => {
          this.$message.destroy();
          this.$message.info("" + this.$t("upload.fail"));
        });

      delta.ops = [];
      return delta;
    },
    onTextPasted(node: any, delta: any) {
      const regex = /https?:\/\/[^\s]+/g;
      if (regex.exec(node.data) != null) {
        delta.ops = [{ insert: node.data, attributes: { link: node.data } }];
      }
      return delta;
    },
    onImageUploaded({ url }: { url: string }) {
      const index = this.addRange ? this.addRange.index : 0;
      this.editor.insertText(index, "\n");
      this.editor.insertEmbed(index, "image", url);
      this.editor.insertText(index, "\n");
    },
    onVideoUploaded({ url }: { url: string }) {
      const index = this.addRange ? this.addRange.index : 0;
      this.editor.insertText(index, "\n");
      this.editor.insertEmbed(index, "video", url);
      this.editor.insertText(index, "\n");
    },
    onFileUploaded({ url, name }: { url: string; name: string }) {
      const index = this.addRange ? this.addRange.index : 0;
      this.editor.insertText(index, "\n");
      this.editor.insertText(index, name, "file", {
        href: url,
        download: name,
        rel: "noopener noreferrer",
        target: "_blank"
      });
      this.editor.insertText(index, "\n");
    },
    onEditorChange({ html, text }: { html: string; text: string }) {
      this.addRange = this.editor.getSelection();
      this.comment = html;
      this.text = text;
    },
    onEditorFocus() {
      if (this.isMobile) {
        setTimeout(() => {
          // (this.$refs.title as HTMLElement).scrollIntoView({
          //   behavior: "smooth",
          //   block: "start"
          // });
        }, 700);
      }
    },
    onSend() {
      if (!this.user.isSignIn) {
        dispatch.userSignIn();
      }
      if (this.comment.length > 2000) {
        this.$message.info("" + this.$t("task.commentMax"));
        return;
      }

      const ids: number[] = [];

      document
        .querySelectorAll(".v-task-comment-editor .ql-editor .mention")
        .forEach(mention => {
          const id = mention.getAttribute("data-id");
          id && !ids.includes(Number(id)) && ids.push(Number(id));
        });

      dispatch
        .commentsPost({
          content: this.comment,
          content_type: "coin_task",
          object_id: this.taskId,
          mention_user_ids: ids,
          superior_id: this.commentId || 0
        })
        .then(() => {
          this.comment = "";
          this.addRange = null;
          this.$message.info("" + this.$t("task.sendSuccess"));
          this.$emit("change");
        });
    }
  },
  mounted() {
    if (this.editor) {
      this.editor.getModule("clipboard").addMatcher("img", this.onImagePasted);
      this.editor
        .getModule("clipboard")
        .addMatcher(Node.TEXT_NODE, this.onTextPasted);
    }
  }
});
