

























import Vue, { PropType } from "vue";
import { mapState, mapGetters } from "vuex";
import { dispatch, ProductName } from "../store";

export default Vue.extend({
  name: "VProductLinks",
  props: {
    name: String as PropType<ProductName>,
    buyHidden: Boolean
  },
  computed: {
    ...mapState("app", {
      isMobile: "isMobile"
    }),
    ...mapGetters({ getStatus: "getStatus" }),
    buyText(): string {
      if (this.getStatus(this.name) === "restock") {
        return this.$t("GBNow") + "";
      } else if (this.getStatus(this.name) === "GB") {
        return this.$t("GBNow") + "";
      } else if (this.getStatus(this.name) === "inStock") {
        return this.$t("orderNow") + "";
      }
      return "";
    }
  },
  methods: {
    onLearnMore() {
      dispatch.learnMore(this.name);
      this.$emit("learnMore");
    },
    onOrderNow() {
      dispatch.orderNow(this.name);
      this.$emit("orderNow");
    }
  }
});
