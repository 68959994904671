import { Commit } from "vuex";
import { RootState } from "@/store";

export interface QrCodeState {
  visible: boolean;
  title: string;
  desc: string;
  src: string;
  url?: string;
}

const state: QrCodeState = {
  visible: false,
  title: "",
  desc: "",
  src: ""
};

interface Context {
  commit: Commit;
  state: QrCodeState;
  rootState: RootState;
}

const actions = {
  set({ commit, rootState }: Context, qrCode: QrCodeState) {
    if (rootState.app.isMobile && qrCode.visible && qrCode.url) {
      window.open(qrCode.url, "_blank");
    } else {
      commit("SET", qrCode);
    }
  }
};

const mutations = {
  SET: (state: QrCodeState, qrCode: QrCodeState) => {
    state.visible = qrCode.visible;
    state.title = qrCode.title;
    state.desc = qrCode.desc;
    state.src = qrCode.src;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
