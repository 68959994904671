







import Vue, { PropType } from "vue";
import { mapState } from "vuex";
import { Group } from "@/store";

export default Vue.extend({
  name: "VUserGroup",
  props: {
    group: Array as PropType<Group[]>
  },
  computed: {
    ...mapState("app", {
      isCN: "isCN"
    })
  }
});
