



































import Vue, { PropType } from "vue";
import { mapState, mapGetters } from "vuex";
import { dispatch, ListQuery, Comment, Task } from "@/store";

export default Vue.extend({
  name: "VTaskComments",
  props: {
    taskId: Number,
    task: Object as PropType<Task>
  },
  data() {
    return {
      time: new Date().getTime(),
      query: {
        page_number: 1,
        page_size: 100,
        ordering: "-id",
        content_type: "coin_task",
        object_id: null,
        superior_id: 0
      } as ListQuery,
      list: [] as Comment[],
      count: 0,
      timer: 0
    };
  },
  computed: {
    ...mapState({ user: "user" }),
    ...mapGetters({ allow: "allow" }),
    ...mapState("app", {
      isCN: "isCN",
      isMobile: "isMobile"
    }),
    ...mapState("assets", {
      images: "images"
    }),
    commentVisible(): boolean {
      const { status, operation } = this.task;
      return (
        (!this.user.isSignIn || this.allow("comment", "retrieve")) &&
        status === "processing" &&
        operation === "manual"
      );
    }
  },
  methods: {
    getList() {
      dispatch.commentsGetList(this.query).then(res => {
        if (res.page_number > 1 && this.isMobile) {
          this.list = [...this.list, ...res.results];
        } else {
          this.list = res.results;
        }
        this.count = res.count;
        this.time = new Date().getTime();
      });
    },
    moreData() {
      this.query.page_number = Number(this.query.page_number) + 1;
      this.getList();
    },
    onPaginationChange(page: number) {
      this.query.page_number = page;
      this.getList();
    },
    onRefresh() {
      if (this.isMobile) {
        this.query.page_number = 1;
      }
      this.getList();
    }
  },
  mounted() {
    if (this.taskId) {
      this.query.object_id = this.taskId;
      this.getList();
    }
    if (window.location.hash.includes("task-comment-")) {
      this.timer = setInterval(() => {
        const comment = document.querySelector(window.location.hash);
        if (comment) {
          const offset =
            comment.getBoundingClientRect().top + window.pageYOffset - 50;
          window.scrollTo({
            top: offset,
            behavior: "smooth"
          });
          clearInterval(this.timer);
        }
      }, 1000);
    }
  },
  beforeDestroy() {
    this.timer && clearInterval(this.timer);
  }
});
