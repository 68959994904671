import { Commit } from "vuex";
import { request, url } from "@/api";
import { RootState } from "@/store";

export interface Captcha {
  scene: string;
  session_id: string;
  sign: string;
  token: string;
}

export interface Verify {
  captcha: Captcha;
  sn_code: string;
  security_code?: string;
}

const state = {};

interface Context {
  commit: Commit;
  rootState: RootState;
}

const actions = {
  check(context: Context, verify: Verify) {
    return request({
      method: "post",
      url: url.check,
      data: verify
    });
  }
};

const mutations = {};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
