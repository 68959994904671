import { Commit } from "vuex";
import { RootState } from "@/store";
import { request, url } from "@/api";

interface Context {
  commit: Commit;
  rootState: RootState;
}

const actions = {
  chat(context: Context, data: any) {
    return fetch(url.chatStream, {
      headers: {
        accept: "text/event-stream",
        "content-type": "application/json"
      },
      body: JSON.stringify(data),
      method: "POST"
    });
  },
  addPrompt(context: Context, data: { text: string; token: string }) {
    return request({
      url: url.dataFactory,
      method: "POST",
      headers: {
        ["No-Authorization"]: true,
        ["Authorization"]: "Token " + data.token
      },
      data: { text: data.text }
    });
  },
  asr(context: Context, audio: Blob) {
    const formData = new FormData();
    formData.append("audio", audio);
    return request({
      url: url.asr,
      method: "POST",
      data: formData
    });
  },
  signInPhoneCode(context: Context, phone: string) {
    return request({
      url: url.signInPhoneCode,
      method: "POST",
      data: { phone }
    });
  },
  signInPhone(context: Context, data: { phone: string; code: string }) {
    return request({
      url: url.signInPhone,
      method: "POST",
      data
    });
  }
};

const mutations = {};

export default {
  namespaced: true,
  actions,
  mutations
};
