import Vue from "vue";
import Swiper, {
  Navigation,
  Pagination,
  Mousewheel,
  Autoplay,
  EffectFade
} from "swiper";
import VueAwesomeSwiper from "vue-awesome-swiper";
import "swiper/swiper.min.css";

Swiper.use([Navigation, Pagination, Mousewheel, Autoplay, EffectFade]);
Vue.use(VueAwesomeSwiper);
