













































































































import Vue from "vue";
import { mapState } from "vuex";
import { dispatch } from "@/store";

export default Vue.extend({
  name: "VFooter",
  data() {
    return {
      year: new Date().getFullYear()
    };
  },
  computed: {
    ...mapState("assets", {
      images: "images",
      url: "url"
    }),
    ...mapState("app", {
      language: "language",
      isCN: "isCN",
      isMobile: "isMobile"
    })
  },
  methods: {
    onItemClick(path: string) {
      this.$router.push(path);
    },
    iconHref(type: string) {
      return this.url[`${type}_${this.language}`] || this.url[type];
    },
    setLanguage(language: string) {
      const oldLanguage = this.$route.params.language;
      if (oldLanguage) {
        this.$router.replace({
          path: this.$route.path.replace(oldLanguage, language),
          query: this.$route.query
        });
      } else {
        this.$router.replace({
          path: "/" + language + this.$route.path,
          query: this.$route.query
        });
      }
    },
    contactUs() {
      dispatch.contactUs();
    }
  }
});
