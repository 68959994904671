





























import Vue, { PropType } from "vue";
import { mapState } from "vuex";
import "swiper/swiper-bundle.min.css";

document.querySelector;

export default Vue.extend({
  name: "VSwiper",
  props: {
    options: Object,
    images: {
      type: Array as PropType<string[]>,
      default: () => []
    },
    names: {
      type: Array as PropType<string[]>,
      default: () => []
    }
  },
  data() {
    return {
      defaultOptions: {
        speed: 600,
        shortSwipes: false,
        longSwipesMs: 50,
        longSwipesRatio: 0.05
      }
    };
  },
  computed: {
    ...mapState("app", {
      isMobile: "isMobile"
    }),
    key(): string {
      let key = "";
      if (this.images[0]) {
        key = this.images[0].slice(-10);
        key = key.replace(/\./g, "_");
      }
      return key;
    }
  }
});
