

































import Vue from "vue";
import { mapState } from "vuex";

export default Vue.extend({
  name: "VVideo",
  props: {
    src: {
      type: String,
      required: true
    },
    autoplay: Boolean,
    muted: Boolean,
    loop: Boolean,
    controls: Boolean,
    playback: String,
    snapshot: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      isPlay: false
    };
  },
  computed: {
    ...mapState("assets", {
      images: "images"
    }),
    ...mapState("app", {
      isMobile: "isMobile"
    }),
    video(): HTMLMediaElement {
      return this.$refs.video as HTMLMediaElement;
    }
  },
  methods: {
    play() {
      this.isPlay = true;
      this.video.play().catch(e => e);
    },
    onVideoClick() {
      if (this.controls) {
        return;
      }
      if (this.isPlay) {
        this.video.pause();
      } else {
        this.video.play();
      }
    }
  }
});
