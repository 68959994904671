











































import Vue, { PropType } from "vue";
import { mapState } from "vuex";
import { dispatch, ProductName } from "@/store";

export default Vue.extend({
  name: "VRequest",
  props: {
    name: String as PropType<ProductName>,
    visible: Boolean
  },
  data() {
    return {
      mobile: "",
      email: "",
      nickname: "",
      loading: true,
      requested: false
    };
  },
  computed: {
    ...mapState(["assets"]),
    ...mapState("app", {
      isCN: "isCN"
    })
  },
  methods: {
    onOk() {
      if (this.requested) {
        this.$emit("cancel");
      } else {
        const mobileRe = /^1(3\d|4[5-9]|5[0-35-9]|6[567]|7[0-8]|8\d|9[0-35-9])\d{8}$/;
        if (this.isCN && !mobileRe.test(this.mobile)) {
          this.$message.info("" + this.$t("inputError", [this.$t("mobile")]));
          return;
        }
        const emailRe = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
        if (!this.isCN && !emailRe.test(this.email)) {
          this.$message.info("" + this.$t("inputError", [this.$t("email")]));
          return;
        }
        if (!this.nickname.trim()) {
          this.$message.info(
            "" + this.$t("inputError", [this.$t("request.linkman")])
          );
          return;
        }
        dispatch
          .request({
            name: this.name,
            nickname: this.nickname,
            mobile: this.mobile,
            email: this.email
          })
          .then(() => {
            this.requested = true;
          });
      }
    }
  },
  watch: {
    visible(visible) {
      if (visible) {
        this.loading = true;
        this.requested = false;
        dispatch.requestInfo(this.name).then(res => {
          res.id &&
            dispatch.request({
              name: this.name,
              nickname: res.nickname,
              mobile: res.phone,
              email: res.email
            });
          this.loading = false;
          this.requested = !!res.id;
        });
      }
    }
  }
});
