






















































































import Vue, { PropType } from "vue";
import { mapState, mapGetters } from "vuex";
import { dispatch, ListQuery, Comment, Task } from "@/store";

export default Vue.extend({
  name: "VTaskReplyList",
  props: {
    taskId: Number,
    task: Object as PropType<Task>,
    topId: Number,
    comment: Object as PropType<Comment>
  },
  data() {
    return {
      time: new Date().getTime(),
      query: {
        page_number: 1,
        page_size: 100,
        ordering: "id",
        content_type: "coin_task",
        object_id: null,
        top_id: 0
      } as ListQuery,
      list: [] as Comment[],
      count: 0
    };
  },
  computed: {
    ...mapState({ user: "user" }),
    ...mapState("app", {
      isCN: "isCN",
      isMobile: "isMobile"
    }),
    ...mapState("assets", {
      images: "images"
    }),
    ...mapGetters(["allow"])
  },
  methods: {
    getList() {
      dispatch.commentsGetList(this.query).then(res => {
        if (res.page_number > 1) {
          this.list = [...this.list, ...res.results];
        } else {
          this.list = res.results;
        }
        this.count = res.count;
        this.time = new Date().getTime();
      });
    },
    moreData() {
      this.query.page_number =
        this.list.length === 1 ? 1 : Number(this.query.page_number) + 1;
      this.query.page_size = 100;
      this.getList();
    },
    onRefresh() {
      this.query.page_number = 1;
      this.query.page_size = 100;
      this.getList();
    },
    onReply() {
      this.comment.reply_count = (this.comment.reply_count || 0) + 1;
      this.onRefresh();
    },
    onDelete(comment: Comment) {
      this.$confirm({
        title: this.$t("task.deleteConfirm"),
        centered: true,
        okText: "" + this.$t("task.ok"),
        cancelText: "" + this.$t("task.cancel"),
        onOk: () => {
          dispatch.commentsDelete(comment).then(() => {
            this.$message.info("" + this.$t("task.deleteSuccess"));
            this.comment.reply_count = (this.comment.reply_count || 0) - 1;
            this.onRefresh();
          });
        }
      });
    },
    onLike(comment: Comment) {
      const id = Number(comment.id);
      if (!this.user.isSignIn) {
        dispatch.userSignIn();
      }
      (comment.is_like
        ? dispatch.commentsUnlike(id)
        : dispatch.commentsLike(id)
      ).then(res => {
        comment.like_count = res.count;
        comment.is_like = !comment.is_like;
      });
    }
  },
  mounted() {
    this.query.top_id = this.topId;
    this.query.object_id = this.taskId;
    this.getList();
  }
});
